export const getInitials = (item: string) => {
  if (!item) return "";
  const split = item.split(" ");
  return `${item[0]}${split[1] ? split[1][0] : ""}`;
};

export const getObjectPropertyByString = (propertyName: any, object: any) => {
  let property = object;
  let i;
  const parts = propertyName.split(".");
  const length = parts.length;

  for (i = 0; i < length; i++) {
    property = property[parts[i]];
  }

  return property;
};

export const sortFunc = (a: any, b: any) => {
  if (!isNaN(a) && !isNaN(b)) {
    return Number(a) - Number(b);
  } else {
    return a < b ? -1 : a > b ? 1 : 0;
  }
};

export const genericSort = (items: any, sortBy: string) => {
  if (!sortBy) {
    return items;
  }

  const numbers = items
    .filter((item: any) => item[sortBy] && Number(item[sortBy]))
    .sort((a: any, b: any) => sortFunc(a[sortBy], b[sortBy]));

  const strings = items
    .filter((item: any) => item[sortBy] && !Number(item[sortBy]))
    .sort((a: any, b: any) => sortFunc(a[sortBy], b[sortBy]));

  const undefinedes = items.filter((item: any) => !item[sortBy]);

  const result = [...numbers, ...strings, ...undefinedes];

  return result;
};

export const shiftArray = (arr: any[], numPlaces: number) => {
  numPlaces = numPlaces % arr.length;

  if (numPlaces < 0) {
    numPlaces += arr.length;
  }

  return arr.slice(numPlaces).concat(arr.slice(0, numPlaces));
};
