export const formatDate = (timestamp: Date): string => {
  return `${timestamp.getFullYear()}-${("0" + (timestamp.getMonth() + 1)).slice(
    -2,
  )}-${("0" + timestamp.getDate()).slice(-2)}`;
};

export const formatDateYYYYMM = (timestamp: Date): string => {
  const date = new Date(timestamp);

  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}`;
};

/**
 * Function that helps convert number to currency
 * @param val number Value to convert
 * @param currency
 */
export const formatCurrency = (val: number, currency: string): string => {
  if (typeof val !== "number" || !currency?.length || isNaN(val)) return ``;

  const formater = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  });
  return formater.format(val);
};

/**
 * Functions converts currency string to number format
 * @param val {string} string that will be converted to number and replace , to .
 */
export const formatToNumber = (val: string): number => {
  const value = val.match(/\d|\./g) || [""];
  return parseFloat(value?.join(""));
};
