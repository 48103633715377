import { Route, NavigationGuardNext } from "vue-router";
import { store } from "@/store/index";
import router from "./index";

store.subscribe(({ type, payload }) => {
  if (type === "auth/setUser") {
    if (payload != null) {
      store.state.loading = true;
      store.dispatch("setWebSocket");
    } else {
      router.push("/login");
    }
  }

  if (type === "CONNECTION") {
    store.state.loading = false;
    if (payload) {
      // if(router.history._startLocation === router.)
      /* eslint-disable */
      const r: string =
        // @ts-ignore
        router.history._startLocation.indexOf("/login") > -1
          ? "/"
          : // @ts-ignore
            router.history._startLocation;
      /* eslint-enable */
      router.push(r);
    }
  }
});

export const authorize = async (
  to: Route,
  from: Route,
  next: NavigationGuardNext<any>,
) => {
  const token = store.getters["auth/token"];
  if (token) {
    return next();
  }

  return next("/login");
};
