import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { ACTION, MessageBody } from "@/types/websocket";
import { ProjectsFilterGroup } from "@/types/general";

export interface SettingsState {
  entriesPerPage: number;
  filters: ProjectsFilterGroup[];
  [key: string]: any;
}

@Module({ namespaced: true })
export default class Settings extends VuexModule {
  public _settings: any = {
    filters: [],
  };
  public filtersCache: ProjectsFilterGroup[] = [];
  public debounce!: NodeJS.Timeout;

  public get settings() {
    return this._settings;
  }

  public get filters() {
    return JSON.parse(localStorage.getItem("filtersCache") || "[]");
  }

  @Mutation
  public SET_FILTERS(filters: ProjectsFilterGroup[]) {
    localStorage.setItem("filtersCache", JSON.stringify(filters));
    this.filtersCache = filters;
  }

  @Mutation
  public SET_SETTINGS(settings: any) {
    this._settings = settings;
  }

  @Action({ commit: "SET_FILTERS" })
  public setFilters(filters: ProjectsFilterGroup[]) {
    return filters;
  }

  @Action
  public update(settings: any) {
    this.context.commit("SET_SETTINGS", settings);
    // this.context.dispatch(
    //   "entries/fetchEntries",
    //   { clientID: this.settings.clientID },
    //   { root: true }
    // );
    this.context.dispatch(
      "emit",
      {
        action: ACTION.SETTINGS,
        payload: settings,
      } as MessageBody,
      { root: true },
    );
  }
}
