import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { ACTION, MessageBody, METHOD } from "@/types/websocket";
import { NonProjectCosts } from "@/types/costs";

interface CostsItems {
  count: number;
  items: NonProjectCosts[];
}

@Module({ namespaced: true })
export default class Costs extends VuexModule {
  public _costs: CostsItems = {
    count: 0,
    items: [],
  };

  public get costs() {
    return this._costs.items;
  }

  @Mutation
  public SET_COST(cost: any) {
    this._costs.items.splice(
      this._costs.items.findIndex((c: any) => c.id === cost.id),
      1,
      cost,
    );
  }

  @Mutation
  public SET_COSTS(costs: any) {
    this._costs = costs;
  }

  @Action
  public get(clientID: string) {
    this.context.rootState.loading = true;

    this.context.dispatch(
      "emit",
      {
        action: ACTION.COSTS,
        type: METHOD.GET,
        payload: {
          clientID,
        },
      },
      { root: true },
    );
  }

  @Action
  public save(cost: any): void {
    this.context.rootState.loading = true;

    this.context.dispatch(
      "emit",
      {
        action: ACTION.COSTS,
        type: METHOD.POST,
        payload: cost,
      } as MessageBody,
      { root: true },
    );

    if (this.costs.some((e) => e.id === cost.id)) {
      this.context.commit("SET_COST", cost);
    } else {
      this.costs.push(cost);
      this.context.commit("SET_COSTS", this._costs);
    }
  }
}
