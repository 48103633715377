import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { ACTION, MessageBody, METHOD } from "@/types/websocket";
import { Vendor, VendorsResponse } from "@/types/vendors";

@Module({ namespaced: true })
export default class VendorsModule extends VuexModule {
  public _vendors: VendorsResponse = {
    count: 0,
    items: [],
  };

  public get vendors() {
    return this._vendors.items;
  }

  @Mutation
  public SET_VENDORS(vendors: VendorsResponse) {
    this._vendors = vendors;
  }

  @Mutation
  public SET_VENDOR(vendor: Vendor) {
    this._vendors.items.splice(
      this._vendors.items.findIndex((c: Vendor) => c.id === vendor.id),
      1,
      vendor,
    );
  }

  @Action
  public getVendorById(id: string) {
    return this.vendors.find((v) => v.id === id);
  }

  @Action
  public save(vendor: Vendor) {
    this.context.dispatch(
      "emit",
      {
        action: ACTION.VENDORS,
        type: METHOD.POST,
        payload: vendor,
      } as MessageBody,
      { root: true },
    );

    if (this._vendors.items.some((e) => e.id === vendor.id)) {
      this.context.commit("SET_VENDOR", vendor);
    } else {
      this._vendors.items.push(vendor);
      this.context.commit("SET_VENDORS", this._vendors);
    }
  }
}
