<template>
  <v-app>
    <!-- <div class="maintenance">
      Site under maintenance. Please give us a moment.
    </div> -->
    <router-view />
    <notifications group="n" class="notifications" />
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Action } from "@/store/decorator";

@Component({})
export default class App extends Vue {
  public snackbar = false;

  @Action("currentUser", { namespace: "auth" })
  public currentUser!: () => Promise<void>;

  created() {
    this.currentUser();

    document.addEventListener(
      "NewContent",
      (e: any) => {
        console.log("new content");
        console.log(e);
        e.detail.waiting.postMessage({ type: "SKIP_WAITING" });
        window.location.reload();
      },
      { once: true },
    );
  }
}
</script>
<style lang="scss">
body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.notifications {
  top: 80px !important;
}
/* .maintenance {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000000000;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
} */
</style>
