import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { authorize } from "./auth";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () => import("@/pages/Dashboard.vue"),
    beforeEnter: authorize,
    children: [
      {
        path: "",
        component: () => import("@/views/dashboard/dashboard.view.vue"),
      },
      {
        path: "projects",
        component: () => import("@/pages/Invoices.vue"),
        children: [
          {
            path: "",
            component: () => import("@/views/projects/project-list.view.vue"),
            children: [
              {
                path: "new",
                component: () =>
                  import("@/views/projects/project-form.view.vue"),
              },
              {
                path: "edit/:id",
                component: () =>
                  import("@/views/projects/project-form.view.vue"),
                children: [
                  {
                    path: "changelog",
                    component: () =>
                      import("@/components/change-log.component.vue"),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "reports",
        component: () => import("@/pages/Reports.vue"),
        redirect: "/reports/rf",
        children: [
          {
            path: ":id",
            component: () => import("@/views/reports/report-details.view.vue"),
          },
        ],
      },
      {
        path: "costs",
        component: () => import("@/pages/Costs.vue"),
        beforeEnter: authorize,
        children: [
          {
            path: "",
            component: () => import("@/views/costs/costs-list.view.vue"),
          },
          {
            path: "new",
            component: () => import("@/views/costs/costs-form.view.vue"),
          },
          {
            path: "edit/:id",
            component: () => import("@/views/costs/costs-form.view.vue"),
          },
        ],
      },
      {
        path: "vendors",
        component: () => import("@/pages/Vendors.vue"),
        children: [
          {
            path: "",
            component: () => import("@/views/vendors/vendor-list.view.vue"),
          },
          {
            path: "new",
            component: () => import("@/views/vendors/vendor-form.view.vue"),
          },
          {
            path: "edit/:id",
            component: () => import("@/views/vendors/vendor-form.view.vue"),
          },
        ],
      },
      {
        path: "settings",
        component: () => import("@/pages/Settings.vue"),
      },
      {
        path: "/clients",
        component: () => import("@/pages/Clients.vue"),
        beforeEnter: authorize,
        children: [
          {
            path: "",
            component: () => import("@/views/clients/clients-list.view.vue"),
          },
          {
            path: "new",
            component: () => import("@/views/clients/client-form.view.vue"),
          },
          {
            path: "edit/:id",
            component: () => import("@/views/clients/client-form.view.vue"),
          },
        ],
      },
      {
        path: "/users",
        component: () => import("@/pages/Users.vue"),
        beforeEnter: authorize,
        children: [
          {
            path: "",
            component: () => import("@/views/users/users-list.view.vue"),
          },
          {
            path: "new",
            component: () => import("@/views/users/users-form.view.vue"),
          },
          {
            path: "edit/:id",
            component: () => import("@/views/users/users-form.view.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/pages/Login.vue"),
    children: [
      {
        path: "",
        name: "Login",
        component: () => import("@/views/login/login.view.vue"),
      },
      {
        path: "reset",
        name: "Reset",
        component: () => import("@/views/login/reset-password.view.vue"),
      },
      {
        path: "forgot",
        name: "Forgot",
        component: () => import("@/views/login/forgot-password.view.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: import.meta.env.BASE_URL,
  routes,
});

export default router;
