import { store } from "@/store";

export const Loader = (
  target: any,
  propertyKey: string,
  descriptor: PropertyDescriptor
) => {
  const classMethod = descriptor.value;
  descriptor.value = function(...args: any[]) {
    store.state.loading = true;
    return classMethod
      .apply(this, args)
      .then((v: any) => {
        store.state.loading = false;
        return v;
      })
      .catch((err: any) => {
        store.state.loading = false;
        return Promise.reject(err);
      });
  };
};
