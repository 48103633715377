import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import {
  signIn,
  signOut,
  AuthUser,
  fetchAuthSession,
  JWT,
  fetchUserAttributes,
  confirmResetPassword,
  resetPassword,
  updateUserAttribute,
  AuthError,
  confirmSignIn,
} from "aws-amplify/auth";
import { Credentials } from "@/types/user.store";
import { Loader } from "@/helpers/loader";
import router from "@/router";
import { ACTION, MessageBody, METHOD } from "@/types/websocket";
import * as Sentry from "@sentry/vue";

@Module({ namespaced: true })
export default class User extends VuexModule {
  public _user?: AuthUser;
  public _error: object | null = null;
  public _accessToken?: JWT;

  public get user() {
    return this._user;
  }

  public get error() {
    return this._error;
  }

  public get token(): string | undefined {
    return this._accessToken?.toString();
  }

  public get groups() {
    return this._accessToken?.payload["cognito:groups"] || [];
  }

  @Mutation
  public setUser(user: AuthUser) {
    this._user = user;
  }

  @Mutation
  public setError(error: object) {
    this._error = error;
  }

  @Mutation
  public accessToken(token: JWT) {
    this._accessToken = token;
  }

  @Action
  @Loader
  public async login({ email, password }: Credentials) {
    try {
      const user = await signIn({ username: email, password });
      switch (user.nextStep.signInStep) {
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
          router.push("/login/reset");
          break;
        case "RESET_PASSWORD":
          router.push("/login/reset");
          break;
        case "CONFIRM_SIGN_UP":
          router.push("/login/confirm");
          break;
        case "DONE":
          await this.context.dispatch("currentUser");
          break;
      }
    } catch (e) {
      console.log(e);
      this.context.commit("setError", e);
      // return null;
      throw new Error((e as AuthError).message);
    }
  }

  @Action
  @Loader
  public async changePassword({ email, password, code }: Credentials) {
    console.log(email, password, code);
    try {
      if (code) {
        const reset = await confirmResetPassword({
          confirmationCode: code,
          newPassword: password,
          username: email,
        });
        console.log(reset);
      } else {
        const reset = await confirmSignIn({
          challengeResponse: password,
        });
        console.log(reset);
      }

      router.push("/");
    } catch (e) {
      console.log(e);
      this.context.commit("setError", e);
      // throw new Error(e);
    }
  }

  @Action
  @Loader
  public async currentUser() {
    try {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      if (!idToken) {
        throw new Error("Not authenticated");
      }
      const user = await fetchUserAttributes();
      this.context.commit("accessToken", idToken);
      this.context.commit("setUser", user);
      Sentry.setUser({
        email: user.email,
      });
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  @Loader
  public async forgotPassword(email: string) {
    try {
      const user = await resetPassword({
        username: email,
      });
      console.log(user);
      router.push("/login/reset?code=");
    } catch (e) {
      console.log(e);
      throw new Error((e as AuthError).message);
    }
  }

  @Action
  @Loader
  public async logout() {
    this.context.rootState.ws.close();
    await signOut();
    this.context.commit("setUser", null);
  }

  @Action
  public async updateDisplayName(displayName: string) {
    this.context.dispatch(
      "emit",
      {
        action: ACTION.USERS,
        type: METHOD.PUT,
        payload: {},
      } as MessageBody,
      { root: true },
    );

    return updateUserAttribute({
      userAttribute: {
        attributeKey: "name",
        value: displayName,
      },
    });
  }
}
