import { ACTION, MessageBody, METHOD } from "@/types/websocket";
export class AppWebSocket extends WebSocket {
  public timeout = 60_000;
  public schedule?: number;
  public token: string;
  public closeInterval?: number;

  constructor(url: string, token: string, protocols?: string | string[]) {
    super(url, protocols);
    this.token = token;

    this.onopen = () => {
      this.scheduleHearthBeat();
    };

    this.onclose = () => {
      clearInterval(this.schedule);
    };

    window.addEventListener("offline", () => {
      this.close();
    });
  }

  private scheduleHearthBeat() {
    if (this.schedule) {
      clearInterval(this.schedule);
    }

    this.schedule = window.setInterval(this.heartBeat.bind(this), this.timeout);
  }

  public heartBeat() {
    this.send(
      JSON.stringify({
        action: "HEARTHBEAT",
        message: "PING",
      }),
    );
    this.closeInterval = window.setInterval(() => {
      this.close();
    }, this.timeout / 2);
  }

  /**
   * Fire event to websocket
   * @param message {object | string}
   */
  public emit(payload: MessageBody) {
    if (this.readyState != 1) {
      this.close();
      throw new Error("Websocket not open while emiting data");
    }

    this.send(
      JSON.stringify({
        ...payload,
        token: this.token,
      }),
    );
    this.scheduleHearthBeat();
  }

  public async asyncEmit(payload: MessageBody): Promise<string> {
    this.emit(payload);

    return new Promise((res, rej) => {
      this.addEventListener("message", (message: MessageEvent) => {
        const { action, payload, type } = JSON.parse(message.data);

        if (action === ACTION.MESSAGE) {
          if (payload) {
            switch (type) {
              case METHOD.POST:
                res("SUCCESS");
                break;
              case "ERROR":
                rej("ERROR");
                break;
            }
          } else {
            rej("No payload");
          }
        }
      });
    });
  }
}

// declare module 'vue/types/vue' {
//   interface Vue {
//     $socket: AppWebSocket;
//   }
// }
