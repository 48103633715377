import { DashboardMonthValue } from "@/types/general";
import { REPORTS_TYPE } from "@/types/report";
import { ACTION, METHOD } from "@/types/websocket";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class Dashboard extends VuexModule {
  public data = {
    report: {
      turnover: [],
      revenue: [],
      accrual: [],
      cobo: [],
      cobi: [],
      prodigius: [],
      nonProject: [],
      costs: [],
      clientID: "",
    },
  };

  public _vendors: DashboardMonthValue[] = [];
  public _reserves: DashboardMonthValue[] = [];

  public get report() {
    return this.data.report;
  }

  public get turnover() {
    return this.data.report.turnover;
  }

  public get revenue() {
    return this.data.report.revenue;
  }

  public get external() {
    return this.data.report.costs;
  }

  public get cobo() {
    return this.data.report.cobo;
  }

  public get cobi() {
    return this.data.report.cobi;
  }

  public get prodigius() {
    return this.data.report.prodigius;
  }

  public get accrual() {
    return this.data.report.accrual;
  }

  public get nonProject() {
    return this.data.report.nonProject;
  }

  public get clientID() {
    return this.data.report.clientID;
  }

  @Mutation
  public SET_DATA(data: any) {
    this.data = data;
  }

  @Mutation
  public CLEAN_DASHBOARD_DATA() {
    this.data = {
      report: {
        turnover: [],
        revenue: [],
        accrual: [],
        cobo: [],
        cobi: [],
        prodigius: [],
        nonProject: [],
        costs: [],
        clientID: "",
      },
    };
  }

  @Action
  public cleanDashboardData() {
    this.context.commit("CLEAN_DASHBOARD_DATA");
  }

  @Action
  public getDataForClientID({ clientID, year }: any) {
    this.context.dispatch(
      "emit",
      {
        action: ACTION.REPORT,
        type: METHOD.GET,
        payload: {
          clientID,
          year,
        },
      },
      { root: true },
    );
  }

  @Action
  public getPMDashboard({ id, month }: any) {
    this.context.dispatch(
      "emit",
      {
        action: ACTION.REPORT,
        type: METHOD.GET,
        payload: {
          clientID: id,
          kind: REPORTS_TYPE.USER,
          month,
        },
      },
      { root: true },
    );
  }
}
