import Vue from "vue";
import "vuetify/dist/vuetify.min.css";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";
import { colors } from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: colors.grey.darken4,
        secondary: colors.grey.base,
        accent: colors.blue.base,
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});
