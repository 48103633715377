export const prod = ["salesplan.publicis.tech"];
const preprod = ["preprod.salesplan.publicis.tech"];
const staging = ["staging.salesplan.publicis.tech"];

let wsUrl = `wss://861aqocbnj.execute-api.eu-west-2.amazonaws.com/dev/`;
let environment = "dev";

const h = window.location.host;
switch (true) {
  case prod.some((u) => h === u):
    environment = "prod";
    wsUrl = `wss://wjroznva53.execute-api.eu-west-2.amazonaws.com/prod/`;
    break;
  case preprod.some((u) => h === u):
    environment = "preprod";
    wsUrl = `wss://wjroznva53.execute-api.eu-west-2.amazonaws.com/prod/`;
    break;
  case staging.some((u) => h === u):
    environment = "staging";
    wsUrl = `wss://861aqocbnj.execute-api.eu-west-2.amazonaws.com/dev/`;
    break;
}

export const websocket = wsUrl;
export const env = environment;
export const aws = {
  Auth: {
    identityPoolId: "eu-west-2:e6ba01c2-badf-4807-90ac-aefce66b0c81",
    region: "eu-west-2",
    userPoolId: "eu-west-2_5E7luq0QN",
    userPoolWebClientId: "71d55ahffkljt0lkpe1u9hmcgb",
  },
};
