interface DecoratorOptions {
  namespace: string;
}

export const Action =
  (name?: string, options?: DecoratorOptions) => (target: any, key: string) => {
    Object.defineProperty(target, key, {
      get() {
        return (payload?: any) =>
          this.$store?.dispatch(
            `${options?.namespace ? `${options?.namespace}/` : ""}${
              name || key
            }`,
            payload,
          );
      },
    });
  };
