import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { ACTION, MessageBody, METHOD } from "@/types/websocket";

import { PM, ActiveUser } from "@/types/user.store";

@Module({ namespaced: true })
export default class Users extends VuexModule {
  public _users: PM[] = [];
  public _groups: string[] = [];
  public _activeUsers: ActiveUser[] = [];
  public _paginationToken?: string;

  public get users() {
    return this._users;
  }

  public get activeUsers() {
    return this._activeUsers;
  }

  public get paginationToken() {
    return this._paginationToken;
  }

  @Mutation
  public SET_USERS(payload: any) {
    this._users = payload.users;
    this._groups = payload.groups;
    this._paginationToken = payload.paginationToken;
  }

  @Mutation
  public SET_PAGINATION_TOKEN(token: string) {
    this._paginationToken = token;
  }

  @Mutation
  public SET_ACTIVEUSER(user: ActiveUser) {
    const index = this._activeUsers.findIndex(
      (_user) => _user.cognitoId === user.cognitoId,
    );

    if (index > -1) {
      this._activeUsers.splice(index, 1, user);
    } else {
      this._activeUsers.push(user);
    }
  }

  @Mutation
  public SET_CONNECTIONS(connections: ActiveUser[]) {
    this._activeUsers = connections;
  }

  @Action
  public createUser(user: any) {
    this.context.rootState.loading = true;
    this.context.dispatch(
      "emit",
      {
        action: ACTION.USERS,
        type: METHOD.POST,
        payload: user,
      } as MessageBody,
      { root: true },
    );
  }

  @Action
  public listUsers() {
    this.context.rootState.loading = true;
    this.context.commit("SET_USERS", { users: [], groups: [] });
    this.context.rootState.ws?.emit(
      {
        action: ACTION.USERS,
        type: METHOD.GET,
        payload: {
          pagination: this.paginationToken,
        },
      } as MessageBody,
      { root: true },
    );
  }

  @Action
  public findUser(email: string) {
    this.context.rootState.loading = true;
    this.context.commit("SET_USERS", { users: [], groups: [] });
    this.context.rootState.ws?.emit(
      {
        action: ACTION.USERS,
        type: METHOD.GET,
        payload: {
          filter: `email^="${email}"`,
        },
      } as MessageBody,
      { root: true },
    );
  }
}
