import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { ACTION, METHOD } from "@/types/websocket";

@Module({ namespaced: true })
export default class Reports extends VuexModule {
  public _data = {};

  public get report() {
    return this._data;
  }

  @Mutation
  public SET_DATA(data: any) {
    this._data = data;
  }

  @Mutation
  public CLEAN_REPORTS_DATA() {
    this._data = {};
  }

  @Action
  public cleanReportData() {
    this.context.commit("CLEAN_REPORTS_DATA");
  }

  @Action
  public getReport(payload: any) {
    this.context.dispatch(
      "emit",
      {
        action: ACTION.REPORT,
        type: METHOD.GET,
        payload,
      },
      { root: true },
    );
  }
}
