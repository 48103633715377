import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import { store } from "@/store/index";

import Notifications from "vue-notification";
import vClickOutside from "v-click-outside";
import * as Sentry from "@sentry/vue";

import { Amplify } from "aws-amplify";
import vuetify from "./plugins/vuetify";
import { aws, env } from "./config";

import { version } from "../package.json";

Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: aws.Auth.identityPoolId,
      userPoolId: aws.Auth.userPoolId,
      userPoolClientId: aws.Auth.userPoolWebClientId,
    },
  },
});

Vue.use(Notifications);
Vue.use(vClickOutside);
Vue.config.productionTip = false;

if (window.location.hostname !== "localhost") {
  Vue.config.devtools = true;

  Sentry.init({
    Vue,
    dsn: "https://fb6649040571d7f9f936ef3f649652ad@sentry.k8.publicis.tech/7",
    environment: env,
    release: version,
    integrations: [
      Sentry.browserTracingIntegration({}),
      Sentry.replayIntegration({}),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    sampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: env === "prod" ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
