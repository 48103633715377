import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Clients, FTClient } from "@/types/client";
import { ACTION, METHOD } from "@/types/websocket";

@Module({ namespaced: true })
export default class Teams extends VuexModule {
  public _clients: Clients = {
    count: 0,
    items: [],
  };

  public _currentClient: FTClient | null = null;

  public get clients() {
    return this._clients.items;
  }

  public get count() {
    return this._clients.count;
  }

  public get currentClient() {
    return this.clients.find((client) => client.id === this._currentClient?.id);
  }

  @Mutation
  public SET_CURRENT_CLIENT(client: FTClient) {
    this._currentClient = client;
  }

  @Mutation
  public SET_CLIENTS(clients: Clients) {
    this._clients = clients;
  }

  @Mutation
  public SET_CLIENT(client: FTClient) {
    this._clients.items.splice(
      this._clients.items.findIndex((c: FTClient) => c.id === client.id),
      1,
      client,
    );
  }

  @Action
  public selectClient(id: string) {
    const client = this._clients.items.find((client) => client.id === id);
    this.context.commit("SET_CURRENT_CLIENT", client);
    this.context.commit("invoice/SET_PG", client?.optionalColumns.pg, {
      root: true,
    });
  }

  @Action
  public client(id: string): FTClient | undefined {
    return this._clients.items.find((client) => client.id === id);
  }

  @Action
  public save(client: FTClient): void {
    this.context.dispatch(
      "emit",
      {
        action: ACTION.CLIENTS,
        type: METHOD.POST,
        payload: client,
      },
      { root: true },
    );

    if (this._clients.items.some((e) => e.id === client.id)) {
      this.context.commit("SET_CLIENT", client);
    } else {
      this._clients.items.push(client);
      this.context.commit("SET_CLIENTS", this._clients);
    }
  }
}
